.contact-section {
	> h1 {
		margin-top: 0;
	}
	&:last-child {
		background: palette(blue, dark-grey);
	}

	@include mq($tablet-narrow) {
		width: 50%;
		float: left;
		border-bottom: 0;

		&:before {
			display: none;
		}
	}

	@include mq($tablet-wide) {
		width: 60%;

		&:last-child {
			width: 40%;
		}
	}
}