.quote {
	@extend %cf;
	padding: 1em 0;

	@include mq($tablet-narrow) {
		margin-left: 1.5em
	}

	@include mq($tablet-wide) {
		margin-left: 5em
	}

	> img {
		float: left;
		margin-right: 2em;
		display: none;

		@include mq($mobile-wide) {
			display: block;
		}
	}

	> blockquote {
		@include mq($tablet-narrow) {
			float: left;
			width: 50%;
			width: calc(100% - 121px - 2em)
		}
	}

	h2 {
		font-weight: normal;
		color: palette(red);
		font-size: 2.2em;
	}
	
	cite {
		font-style: normal;
		font-size: 1.5em;
		font-weight: 600;
		margin-left: 2em;
	}

	p {
		color: white;
		font-style: italic;
		font-size: 1.4em;
		margin-top: 1em;
	}
}