.footer {
	margin: 0 auto;
	max-width: 96em;

	&__nav {
		@extend %cf;
		background-color: palette(grey, dark);
		padding: 2em 2em 1em;
		position: relative;
		margin-bottom: (.8em*3);
		border-top: 1px solid palette(red);

		@include mq($tablet-narrow) {
			padding: 2em 4em 1em;
		}

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: (.8em*3);
			position: absolute;
			bottom: -(.8em*3);
			left: 0;
			background-color: palette(grey, dark);
			border-top: .8em solid palette(red);
			border-bottom: .8em solid palette(red);
		}

		&-wrapper {
			@extend %cf;

			@include mq($mobile-wide) {
				display: table;
				table-layout: fixed;
				float: right;
			}

			@include mq($tablet-narrow) {
				&:before {
					content: url('/Content/imgs/footer-burs.png');
					display: block;
					position: absolute;
					bottom: -2.7em; // eek, magic number
					right: 52.5em; // 150*3 (column width*3) + 4 (margin) + 3.5 (magic)
				}
			}

			> nav {
				float: left;
				width: 50%;
				font-size: 1.2em;
				color: palette(blue, text-on-light);
				padding-left: 1em;
				border-left: 2px solid palette(blue, text-on-light);

				&:last-child {
					width: 100%;
					margin-top: 1em;
				}

				@include mq($mobile-wide) {
					&,
					&:last-child {
						width: (15em/1.2);
					}					

					float: none;
					display: table-cell;

					&:last-child {
						margin: auto;
					}
				}
			}

			h1 {
				font-size: 1em;
				text-transform: uppercase;
			}

			a {
				color: palette(blue, text-on-light);
				transition: color .25s;

				@include attention {
					color: white;
				}
			}
		}
	}

	&__logo {
		background-color: palette(blue);
		padding: 1.3em 4em;
		border-bottom: 1px solid palette(red);

		img {
			display: block;
			width: 12.2em;
			height: 2.9em;
		}
	}

	&__brand {
		@extend %cf;
		padding: 2.8em 2em;
		background-color: palette(grey);
		text-align: center;

		@include mq($tablet-narrow) {
			padding: 2.8em 4em;
		}

		> a {
			display: inline-block;
			@include mq($mobile-wide) {
				display: block;
				float: left;
			}
		}

		img {
			display: block;
		}

		p {
			font-size: .625rem;
			color: palette(blue, text-on-light);
			margin-top: .9em;

			@include mq($mobile-wide) {
				float: right;
			}
		}
	}
}