@mixin nav-is-open {
	@at-root #{selector-replace(&, '.header__nav', '.header__nav.is-open')} {
		@content;
	}
}

.header {
	max-width: 96em;
	margin: 0 auto;
	position: relative;
	z-index: z(nav-over-footer);

	&__utility-nav {
		@extend %cf;

		background-color: palette(blue);
		text-align: right;
		position: relative;
		z-index: z(over-nav);

		> ul {
			@extend %cf;
			float: right;
		}

		li {
			display: block;
			float: left;
		}	

		a {
			font-size: 1.4em;
			line-height: 2;
			margin-right: 1em;
			color: palette(blue, text-on-dark);
		}
	}
	
	&__logo {
		@extend %cf;
		padding: 1.9em 4em;
		z-index: z(over-nav);
		position: relative;
		background-color: palette(grey);
		background-image: linear-gradient(
			to bottom,
			palette(blue),
			palette(grey) 7%,
			palette(grey) 93%,
			palette(blue));

		> a {
			display: inline-block;
		}

		img {
			display: block;
			width: 17.6em;
			height: 4.2em;
		}
	
	}
	&__nav {
		background-color: palette(blue);

		@include mq($tablet-narrow) {
			border-bottom: .8em solid palette(red);
		}

		> button,
		a {
			color: white;
			font-size: 1.4em;
			line-height: 2;
		}

		> button {
			@extend %reset-button;
			background-color: palette(blue);
			position: relative;
			z-index: z(over-nav);
			width: 100%;
			padding-bottom: (0.8em*3/1.4);

			@include mq($tablet-narrow) {
				display: none;
			}

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: (0.8em*3/1.4);
				position: absolute;
				bottom: 0;
				left: 0;
				border-top: (0.8em/1.4) solid palette(red);
				border-bottom: (0.8em/1.4) solid palette(red);
			}

			@include nav-is-open {
				&:before {
					content: '';
					display: block;
					width: 0;
					height: 0;
					position: absolute;
					bottom: -.35em;
					left: 50%;
					margin-left: -0.2em;
					border-top: 0.4em solid palette(red);
					border-left: 0.4em solid transparent;
					border-right: 0.4em solid transparent;
				}
			}
		}
		
		ul {
			position: absolute;
			width: 100%;
			background-color: palette(blue);
			margin-top: -75%;
			padding: 0.4em 0 0.8em;
			transition: margin-top .25s;

			@include nav-is-open {
				margin-top: 0;
			}

			@include mq($tablet-narrow) {
				margin-top: 0;
				padding: 0;
				position: relative;
				display: table;

				> li {
					display: table-cell;
					&:first-child {
						> a {
							&:after {
								display: none;
							}
						}
					}
				}
			}

			@include mq($tablet-wide) {
				table-layout: fixed;
			}
		}

		a {
			position: relative;
			text-align: center;
			display: block;

			@include mq($tablet-narrow) {
				border-bottom: (0.8em/1.4) solid palette(red);
				margin-bottom: (0.8em/1.4);
				transition: border-color .25s;

				@include attention(is-selected) {
					border-bottom-color: white;
				}
			}

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: 0;
				left: 0;
				background-image: linear-gradient(
					to right, 
					palette(blue, text-on-dark), 
					palette(blue));

				@include mq($tablet-narrow) {
					width: 1px;
					height: 100%;
					background-image: linear-gradient(
						to bottom,
						palette(blue),
						palette(blue, text-on-dark));
				}
			}
		}
	}
}