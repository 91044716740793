.about-section {
	&--1 {
		@include mq($tablet-wide) {
			padding-right: 70%;
			position: relative;
			height: 30.6em;
		}

		> .switcher__display {
			display: none;

			@include mq($tablet-wide) {
				display: block;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
	}

	&--2 {
		@extend %cf;
		
		.wrapper {
			@include mq($tablet-wide) {
				width: 50%;
				float: left;
			}
		}

		> .switcher {
			display: none;

			@include mq($tablet-wide) {
				width: 50%;
				float: right;
				display: block;
				margin: 0 -2.6em -2em 0;
			}
		}

		.switcher__item {
			margin: 0 1em 2em 0;

			&.is-active {
				&:before {
					content: '';
					top: 1px;
					left: 1px;
					right: 1px;
					background-color: palette(red);
					height: 1em;
					position: absolute;
				}

				&:after {
					content: '';
					position: absolute;
					top: -.9em;
					left: 50%;
					margin-left: -1em;
					border-bottom: 1em solid palette(red);
					border-left: 1em solid transparent;
					border-right: 1em solid transparent;
				}
			}
		}
	}

	&--3 {
		background: 
			linear-gradient(
				to bottom right,
				palette(blue, light),
				palette(blue, light) 40%,
				rgba(palette(blue, light), .5)
			);
		> .body-section__heading {
			color: white;
		}

		> .switcher {
			@extend %cf;

		}
		
		.switcher__controls {
			@extend %cf;
			width: 100%;
			width: calc(100% + 1em);
		}

		.switcher__item {
			float: left;
			width: 25%;
			padding-right: 1em;
			margin-bottom: 2em;

			@include mq($tablet-wide) {
				width: 12.5%;
			}

			&.is-active {
				&:after {
					content: '';
					position: absolute;
					background-color: palette(red);
					bottom: -.75em;
					height: .5em;
					width: 100%;
					width: calc(100% - 1em);
					left: 0;
				}
			}
		}

		.switcher__text {
			@include mq($tablet-wide) {
				float: right;
				width: 19em;
				width: calc(100% - 65em);
				background-image: url('/Content/imgs/abrasive-tech/timeline/date-sign.png');
				background-position: top -1px left -1.1em;
				background-repeat: no-repeat;
				padding: 1.6em 0 0 1.1em;
			}
		}

		.switcher__title {
			color: palette(red);
			font-size: 2.7em;
			font-weight: 700;
		}

		.switcher__display {
			@include mq($tablet-wide) {
				float: left;
				width: 65em;
			}
		}
	}

	&--4 {
		@extend %cf;
		background: palette(grey);

		@include mq($tablet-wide) {
			padding-left: 25%;	
			background: palette(grey) url('/Content/imgs/abrasive-tech/bkgd.png') no-repeat left center;
			background-size: auto 100%;
		}

		.body-section__heading {
			color: palette(blue);
		}

		> .wrapper {
			@include mq($tablet-narrow) {
				width: 50%;
				float: left;
			}
		}

		> figure {
			color: palette(red);
			text-align: center;

			@include mq($tablet-narrow) {
				width: 50%;
				float: left;
			}
		}
	}

	&--5 {
		@extend %cf;
		position: relative;

		> .wrapper {
			margin: -1em 0;

			@include mq($tablet-narrow) {
				float: left;
				width: 60%;
			}
		}

		> img {
			display: none;

			@include mq($tablet-narrow) {
				position: absolute;
				display: block;
				width: 30%;
				height: auto;
				right: 4em;
				top: 0;
				bottom: 0;
				margin: auto;
			}

			@include mq($tablet-wide) {
				right: 6em;
			}
		}
	}

	&--6 {
		background: palette(blue, dark-grey);
		> .arrow-link {
			color: white;
		}
	}
}