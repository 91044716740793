.buy-section {
	&__items {
		font-style: italic;
		text-transform: uppercase;
		font-size: .75em !important;
	}
}

.resources-section {
	@extend %cf;

	> div {
		+ div {
			margin-top: 2em;
		}
	}
	
	h2 {
		font-size: 1.6em;
		margin-bottom: .25em;
	}
	
	ul {
		font-size: 1.4em;
		list-style-type: none;
	}

	a {
		color: black;
	}

	@include mq($tablet-narrow) {
		> div {
			float: left;
			width: 50%;

			+ div {
				margin-top: 0;
			}
		}
	}
}

.news-section {
	p {
		overflow: hidden;
	}

	a {
		@extend %hide-text;
		position: relative;

		&:before {
			content: 'Event Link';
			position: absolute;
			left: 0;
			line-height: 1;
			border-bottom: 1px solid white;
		}
		@include mq($mobile-wide) {
			text-indent: 0;
			white-space: normal;
			&:before {
				content: '';
			}
		}
	}
}

.accordion__content a[href^=mailto] {
	font-size: .85em;
	
	@include mq($mobile-wide) {
		font-size: 1em;
	}
}

.jx-handle {
	&:before {
		content: '';
		width: 100px;
		height: 100%;
		position: absolute;
		left: -30px;
	}
}