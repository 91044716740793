.switcher {
	img {
		display: block;
		border: 1px solid white;
	}

	&__controls {
		@extend %cf;
	}

	&__item {
		float: left;
		position: relative;
		
		> img {
			cursor: pointer;
		}
	}
}