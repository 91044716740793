$palettes: (
	red: (
		base: #e71939
	),
	blue: (
		base: #1b212e,
		light: #516784,
		grey: #bcc3ce,
		dark-grey: #4a4f58,
		text-on-light: #4a4f58,
		text-on-dark: #9da5ab
	),
	grey: (
		base: #d1d3d4,
		dark: #9da5ab
	)
);

$z-index: (
	over-nav: 1,
	nav-over-footer: 1,
	over-downloads: 1,
	modal: 100
);

$fallback: Helvetica, Arial, sans-serif;
$regular: 'Titillium Web', $fallback;
$reg: $regular;

// BREAKPOINTS
$hi-res: min-resolution 1.5dppx;
$mobile-wide: 480px;
$tablet-narrow: 720px;
$tablet-wide: 1024px;
$laptop: 1200px;
$desktop: 1600px;

// MQPacker Order Definition
@include mq($mobile-wide) { /* 480px */ }
@include mq($tablet-narrow) { /* 720px */ }
@include mq($tablet-wide) { /* 1024px */ }
@include mq($laptop) { /* 1200px */ }
@include mq($desktop) { /* 1600px */ }
