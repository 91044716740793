.body-section {
	@extend %section-divide;
	color: white;
	padding: 2em;
	background-color: palette(grey);
	background-image: url('/Content/imgs/dark-bkgd.png');
	background-size: cover;

	> .minimize-safe {
		display: none;
	}

	&.is-closed {
		> * {
			display: none;
		}
		
		> .minimize-safe {
			display: block;	
		}
	}

	&.is-closed-completely {
		display: none;
	}

	&:last-child {
		border-bottom: 0;
	}

	&:nth-of-type(odd) {
		&:before {
			right: auto;
			left: -3em;
		}
	}

	@include mq($tablet-narrow) {
		padding: 4em;
	}

	@include mq($tablet-wide) {
		padding: 4em 6em;
	}

	&--light {
		color: black;
		background-image: none;
	}

	&--white {
		background: white;
		color: black;
	}

	&__title {
		display: inline-block;
		font-size: 1em;
		text-transform: uppercase;
		border-bottom: 1px solid currentColor;

		> a {
			color: currentColor;
			display: inline-block;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: calc(100% + 4em);
				height: 6em;
				left: -2em;
				top: -2.5em;
			}
		}
	}

	&__heading {
		font-weight: normal;
		font-size: 2.6em;
		margin: .5em 0;
		color: palette(red);
		> strong {
			font-weight: 600;
		}
	}

	p {
		margin: 1em 0;
		font-size: 1.4em;
	}
}