.stars {
	@extend %hide-text;
	width: 14.5em;
	height: 2.5em;
	background: url('/Content/imgs/testimonials/stars.png') no-repeat;

	&--4-half {
		width: 13.3em;
	}

	&--4 {
		width: 12em;		
	}
}