form {
	@extend %cf;
}

label {
	display: block;
	margin: 1.5em 0;

	&.required {
		> p {
			&:after {
				content: '*';
				color: palette(red);
			}
		}
	}
}

select,
textarea,
input {
	width: 100%;
	font-size: 1.4em;
	padding: .25em .5em;
}

.form {
	p {
		margin: 0;		
	}

	&__submit {
		@extend %reset-button;
		background-color: black;
		color: white;
		text-transform: uppercase;
		padding: .5em 1em;
		border-radius: 1em;
		font-size: 1.4em;
		font-weight: bold;
		float: right;
		transition: background .25s;

		&:hover {
			background-color: palette(red);
		}
	}
}