.review {
	@extend %cf;

	@include mq($mobile-wide) {
		float: left;
		width: 50%;
	}

	@include mq($tablet-narrow) {
		> h2 {
			width: 30%;
			float: left;
		}

		dl {
			float: left;
			width: 70%;
		}
	}

	@include mq($tablet-wide) {
		width: 60%;

		> h2 {
			width: 15%
		}

		dl {
			width: 85%;
		}

		dt {
			float: left;
			width: 40%;
		}
	}

	&--short {
		@include mq($tablet-wide) {
			width: 40%;

			h2 {
				width: 50%;
			}

			dl {
				width: 50%;
			}
			
			dt {
				width: 100%;
			}

			ul {
				width: 50%;
			}
		}
	}

	+ .review {
		margin-top: 3em;

		@include mq($mobile-wide) {
			margin-top: 0;
		}
	}
	
	> h2 {
		font-style: italic;
		font-size: 1.8em;
	}

	dt {
		margin: 2em 0 1em;

		@include mq($tablet-narrow) {
			margin-top: 0;

			~ dt {
				margin-top: 1em;
			}
		}
	}
	
	dd {
		@include mq($tablet-wide) {
			float: left;
			width: 60%;

			~ dd {
				margin-top: 1em;
			}
		}
	}

	ul {
		list-style-type: none;
		font-size: 1.8em;
		color: palette(blue, text-on-dark);
		margin-left: 1em;
	}
}