@viewport { width: device-width; }

* {
	margin: 0;
	padding: 0;
	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
	// background-color:;
	// color:;
	// text-shadow:;
}

html {
	box-sizing: border-box;
}

body {
	font: 62.5%/1.25 $regular;
	background: palette(blue, grey) url('/Content/imgs/background.png');
	overflow-x: hidden;
}

body,
input {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe,
video,
img {
	max-width:100%;
}

nav ul {
	list-style-type: none;
}

a {
	text-decoration: none;
}

h1 {
	font-weight: 600;
}

main {
	@extend %cf;
	max-width: 96em;
	margin: 0 auto;
}