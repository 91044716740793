.slick {
	padding: 0;
}

.slick-prev,
.slick-next {
	@extend %reset-button;
	@extend %hide-text;
	width: 3.2em;
	height: 3.2em;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	margin-top: -1.6em;
	background-color: palette(blue, light);
	opacity: .5;

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -1em;
		border-top: 1em solid transparent;
		border-bottom: 1em solid transparent;
	}
}

.slick-prev {
	left: 1.6em;

	&:after {
		margin-left: -0.75em;
		border-right: 1em solid white;
	}
}

.slick-next {
	right: 1.6em;

	&:after {
		margin-left: -0.25em;
		border-left: 1em solid white;
	}
}

.slick-dots {
	list-style: none;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;

	> li {
		display: inline;

		&.slick-active > button {
			background-color: red;			
		}
	}

	button {
		@extend %reset-button;
		@extend %hide-text;
		width: .75em;
		height: .75em;
		border-radius: 50%;
		background-color: white;
		margin: .5em;
		opacity: .5;
	}
}

.slick-slider {
	position: relative;
	display: block;
	-webkit-user-select: none;
	   -moz-user-select: none;
		-ms-user-select: none;
			user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
		touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;

	.slick-track,
	.slick-list {
		transform: translate3d(0, 0, 0);
	}
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;

	&:before,
	&:after {
		display: table;
		content: '';
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}
}