.testimonials-section {
	&--1 {
		@include mq($tablet-wide) {
			background: white url('/Content/imgs/testimonials/bkgd.jpg') center right no-repeat;
			background-size: auto 100%;	
		}

		> h1 {
			> strong {
				@include mq($tablet-wide) {
					display: block;
				}
			}
		}

		> p {
			@include mq($tablet-wide) {
				width: 45%;
			}			
		}
	}

	&--2 {
		@extend %cf;
	}

	&--3 {
		padding: 0;
		
		> h1 {
			background-color: palette(blue, dark-grey);
			color: palette(grey);
			padding: (2em/2.6);
			margin: 0;

			@include mq($tablet-narrow) {
				padding: (2em/2.6) (4em/2.6);
			}

			@include mq($tablet-wide) {
				padding: (2em/2.6) (6em/2.6);
			}
		}

		> .wrapper {
			padding: 2em 2em 2em;
			background: 
				palette(blue, dark-grey) 
				linear-gradient(
					to bottom right,
					palette(blue, grey),
					palette(blue, light));

			@include mq($tablet-narrow) {
				background: url('/Content/imgs/testimonials/quote-bkgd.jpg') center;
				background-size: cover;
				padding: 2em 4em 4em calc(50% - 8em);
			}

			@include mq($tablet-wide) {
				padding: 2em 4em 4em 36em;
				padding: 2em 6em 4em calc(50% - 12em);
			}
		}
	}
}