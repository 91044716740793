%section-divide {
	position: relative;
	border-bottom: 1px solid palette(red);
	
	@include mq($tablet-wide) {		
		&:before {
			content: '';
			display: block;
			width: 2.7em;
			height: 0;
			position: absolute;
			bottom: -2px;
			right: -3em;
			border-top: 3px dotted palette(red);
		}
	}
}