.home-page-section {
	&--1 {
		@include mq($tablet-narrow) {
			padding-right: 55%;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 50%;
				background: url('/Content/imgs/burs.png') no-repeat center right;
				background-size: contain;
			}
		}

		@include mq($tablet-wide) {
			padding-right: 60%;

			&:after {
				width: 60%;
				top: 4em;
				bottom: 4em;
				background-image: url('/Content/imgs/home-page/burs--full.png');
			}
		}
	}

	&--2 {
		@extend %cf;

		> .red-wrapper {
			@include mq($tablet-narrow) {
				width: 45%;
				width: calc(50% - 4em);
				float: right;
				margin-left: 5%;
				margin-left: calc(4em);
			}

			@include mq($tablet-wide) {
				width: 45%;
				width: calc(50% - 8em);
				margin-left: 5%;
				margin-left: calc(8em);
			}
		}

		> video {
			@include mq($tablet-narrow) {
				width: 50%;
				float: right;
			}
		}
	}

	&--3 {
		@extend %cf;
		background: palette(blue, grey);

		@include mq($tablet-wide) {
			background:
				url('/Content/imgs/home-page/catalog-switcher/border-top.png') no-repeat,
				url('/Content/imgs/home-page/catalog-switcher/border-bottom.png') no-repeat,
				linear-gradient(
					to right,
					rgba(palette(blue, grey), .8) 75%,
					palette(blue, light) 75%
			);
			background-position: top right, top right, top left;
			background-position: top -5.4em right 23.9em, bottom .7em right -2.5em, top left;
			background-size: auto, auto, 100% 100%;
		}

		> .wrapper {
			@include mq($tablet-narrow) {
				float: left;
				width: 33.3333%;
			}
		}

		> .switcher {
			@extend %cf;
			display: none;

			@include mq($tablet-narrow) {
				display: block;
				float: right;
				width: 55%;
			}
		}

		.switcher__wrapper {
			@extend %cf;

			@include mq($tablet-wide) {
				float: left;
			}
		}

		.switcher__controls {
			float: left;
			width: 22.2em;

			@include mq($tablet-wide) {
				width: 14.8em;
			}
		}

		.switcher__text {
			clear: left;

			> a {
				position: relative;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 1.9em;
				color: palette(blue);

				&:before {
					content: url('/Content/imgs/home-page/red-arrow.png');
					position: absolute;
					top: 1px;
					left: -.75em;
				}
			}

			@include mq($tablet-wide) {
				width: 13.8em;
			}
		}

		.switcher__item {
			margin-right: 1em;
			margin-bottom: 1em;
			width: 6.4em;
			height: 6.4em;

			&.is-active {
				&:before {
					content:'';
					display: block;
					position: absolute;
					height: 1em;
					bottom: 1px;
					left: 1px;
					right: 1px;
					background-color: palette(red);
				}
			}
		}

		.switcher__display {
			width: 28.8em;
			height: 28.8em;

			@include mq($tablet-wide) {
				float: right;
			}
		}
	}

	&--4 {
		@include mq($tablet-narrow) {
			background-image: url('/Content/imgs/home-page/testimonials-man.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: right;
		}

		p {
			@include mq($tablet-narrow) {
				width: 50%;
			}
		}
	}

	&--5 {
		@extend %cf;

		> img {
			display: none;
		
			@include mq($tablet-narrow) {
				display: block;
				border: 1px solid white;
				float: left;
				margin-right: 4em;
			}
		}

		> .wrapper {
			@include mq($tablet-narrow) {
				float: left;
				width: 50%;
				width: calc(100% - 29em);
			}
		}
	}
}