.download {
	background-color: palette(grey);
	text-align: right;

	@include mq($tablet-narrow) {
		clear: both;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	> a {
		background-color: palette(grey, dark);
		font-size: 1.4em;
		line-height: 2;
		text-transform: uppercase;
		color: black;
		position: relative;
		padding-right: 3em;
		display: block;

		@include mq($mobile-wide) {
			width: 50%;
			display: inline-block;
		}

		@include mq($tablet-narrow) {
			width: 75%;
		}

		@include mq($tablet-wide) {
			width: 50%;
		}

		&:after {
			content: '';
			position: absolute;
			right: 1em;
			top: 50%;
			margin-top: -.25em;
			display: block;
			width: 0;
			height: 0;
			border-top: .5em solid palette(red);
			border-left: .5em solid transparent;
			border-right: .5em solid transparent;
		}
	}
}