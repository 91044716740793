.catalog-section {
	background-color: white;
	border-top: 1px solid palette(grey);
	border-bottom: 2.8em solid white;

	> h1 {
		color: palette(red);
		padding-left: 1em;
		border-left: .5em solid palette(red);
		font-size: 2.5em;
	}

	&__outer-wrapper {
		@extend %cf;
		border-top: 1px solid palette(grey);
		box-shadow: inset 0 0 10px 0 rgba(black, .5);

		@supports (pointer-events: none) {
			box-shadow: none;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				box-shadow: inset 0 0 10px 0 rgba(black, .5);
				z-index: z(over-downloads);
				pointer-events: none;
			}
		}
	}

	&__wrapper {
		@extend %cf;
		padding-top: 2em;
	
		@include mq($tablet-narrow) {
			width: 50%;
			float: left;
			position: relative;
			padding-bottom: 2.8em; // for the download button
		}

		&:nth-child(even) {
			@include mq($tablet-narrow) {
				border-left: 1px solid palette(grey);
			}
		}

		&:nth-child(odd):last-child {
			@include mq($tablet-narrow) {
				width: 100%;

				> .download > a {
					width: 25%;
				}
			}
		}
	
		> img {
			margin: 0 auto;
			display: block;
			
			@include mq($tablet-narrow) {
				max-width: 50%;
				float: left;
				padding: 0 2em 2em;
			}
		}
	}

	&__inner-wrapper {
		padding: 0 2em 2em;
		margin-top: 1em; 

		@include mq($tablet-narrow) {
			float: left;
			width: 50%;
			margin-top: 0;
		}

		> h2 {
			color: palette(red);
			font-size: 1.8em;
		}
		> p {
			font-size: 1.4em;
		}
	}

	&--1 {
		@extend %section-divide;
		@extend %cf;
		text-align: center;
		background-color: palette(grey, dark); 

		> ul {
			list-style-type: none;

			@include mq($tablet-narrow) {
				display: table;
				width: 100%;
			}
		}

		li {
			padding: .5em 0;
			
			@include mq($mobile-wide) {
				width: 50%;
				float: left;
			}

			@include mq($tablet-narrow) {
				width: auto;
				float: none;
				display: table-cell;
			}
		}

		a {
			padding: .5em 0;
			font-size: 1.4em; 
			color: white;
			font-weight: 600;
			display: inline-block;
		}
	}

	&--2 {
		border-bottom: 0;

		&:before {
			display: none;
		}

		@include mq($tablet-wide) {
			background: white url('/Content/imgs/catalog/hero.jpg') no-repeat right center;
			background-size: auto 100%;
		}

		> p {
			@include mq($tablet-wide) {
				width: 50%;				
			}
		}

		a {
			color: palette(red);
		}
	}

	&--3 {
		.catalog-section__wrapper {
			&:first-child {
				> img {
					@include mq($tablet-narrow) {
						margin: 0 calc(25% - 42px);
					}
				}
			}
		}
	}
}