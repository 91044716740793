// When an element gets some 'attention'
@mixin attention($active-class: '') {
	@if $active-class != '' {
		&.#{$active-class},
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	} @else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}