.accordion {
	> h2 {
		display: block;
		color: white;
		background-color: palette(grey, dark);
		font-weight: 400;
		font-size: 1.8em;
		padding: .5em 1em;
		position: relative;
		cursor: pointer;
		margin: 1px 0;
		user-select: none;

		&:before {
			content: '-';
			display: inline;
			padding-right: .5em;
		}

		&:after {
			content: 'Hide';
			position: absolute;
			color: palette(blue);
			font-weight: 700;
			font-size: (1.2em/1.8);
			line-height: 1;
			right: (1.8em/1.2);
			top: 50%;
			margin-top: -.5em;
		}

		&.is-hidden {
			&:before {
				content: '+';
			}

			&:after {
				content: 'Show';
			}
		}
	}

	&__inner {
		@extend %cf;
		background-color: palette(blue, dark-grey);
		color: white;
		padding: 2em 2em;

		@include mq($mobile-wide) {
			padding: 2em 3em;
		}

		a {
			display: inline-block;
			color: white;
		}

		.accordion__content { // for specificity
			> p {
				margin: 0;
				font-size: 1em;
			}
		}

		&.is-hidden {
			display: none;
		}
	}

	&__content {
		border-left: 1px dotted white;
		padding-left: 1em;
		margin-top: 1em;
		font-size: 1.6em;

		&:first-child {
			margin-top: 0;
		}

		@include mq($tablet-narrow) {
			width: 50%;
			float: left;
			margin-top: 0;
		}

		&:nth-child(n+3) {
			@include mq($tablet-narrow) {
				margin-top: 2em;
			}
		}

		> h3 {
			text-transform: uppercase;
			font-size: 1em;
		}

		a {
			word-break: break-all;
		}
	}
}