.modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(palette(blue), .6);
	z-index: z(modal);

	&.is-open {
		display: block;
	}

	&__content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 40em;
		padding: 2.5em;
		background: palette(grey);

		> h1 {
			font-weight: normal;
			font-size: 2.5em;
			margin-bottom: 1em;
		}

		> p {
			font-size: 1.4em;
		}

		a {
			color: palette(red);
		}

		> .arrow-link {
			font-size: 1.2em;
			margin-top: (2.5em/ 1.4);
		}

		&:after {
			content: url('/Content/imgs/logo.svg');
			position: absolute;
			bottom: 2em;
			right: 2em;
			width: 13em;
		}
	}
}