.minimize {
	@extend %reset-button;
	background-color: palette(blue);
	color: palette(red);
	text-transform: uppercase;
	text-align: right;
	display: block;
	width: 100%;
	padding-right: 2em;
	font-size: 1em;
	line-height: 3;
	position: relative;

	&:focus {
		outline: none;
		text-decoration: underline;
	}

	@include mq($tablet-narrow) {
		padding-right: 4em;
	}

	@include mq($tablet-wide) {
		padding-right: 6em;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: -.5em;
		right: 1.5em;
		border-bottom: .5em solid palette(red);
		border-left: .6em solid transparent;
		border-right: .6em solid transparent;

		@include mq($tablet-narrow) {
			right: 3.5em;
		}

		@include mq($tablet-wide) {
			right: 5.5em;
		}
	}


	&.is-closed {
		&:before {
			top: 0;
			border-bottom: none;
			border-top: .5em solid palette(red);
		}
	}

	&__text {
		&--closed {
			display: none;

			.is-closed & {
				display: inline;
			}
		}

		&--open {
			.is-closed & {
				display: none;
			}
		}
	}
}