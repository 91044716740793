.address {
	margin-top: 2em;
	
	&:first-child {
		margin-top: 0;
	}
	
	&__title {
		color: palette(red);
		text-transform: uppercase;
		font-size: 1.6em;
	}

	> p {
		margin: 0;
	}

	+ .arrow-link {
		margin-top: 2em;
		color: white;
	}

	a {
		color: white;
	}
}