.our-story-section {
	&--1 {
		background: white;

		@include mq($tablet-narrow) {
			background: white url('/Content/imgs/burs.png') no-repeat right;
			background-size: auto;
		}

		> p {
			@include mq($tablet-narrow) {
				width: 40%;
			}

			@include mq($tablet-wide) {
				width: 60%;
			}
		}
	}
	
	&--2 {
		@extend %cf;
		background: url('/Content/imgs/our-story/diamonds-bkgd.jpg');
		background-size: cover;

		.wrapper {
			@include mq($tablet-narrow) {
				width: 40%;
				float: left;
				padding-right: 4em;

				+ .wrapper {
					width: 60%;
					padding: 0;
				}
			}
		}

		.jux-wrapper {
			@extend %cf;

			@include mq($tablet-wide) {
				float: left;
				width: 68%;
			}
		}

		.jux-p {
			margin: 0;
			padding: 0;
			border: none;

			@include mq($tablet-wide) {
				margin-left: 1em;
				padding: 1em 0 1em 1em;
				border-left: 1px solid white;
				width: 29%;
				width: calc(32% - 1em);
				float: left;
			}

			&:before,
			&:after {
				display: none;

				@include mq($tablet-wide) {
					display: block;
					background-color: white;
					border-color: white;
				}
			}
		}

		.juxtapose {
			max-width: 48em;
			margin: 0 auto;

			&__caption {
				max-width: 48em;
				margin: 0 auto;

				> span {
					display: block;
					width: 50%;
					float: left;
					color: palette(red);
					font-size: 1.4em;
					text-transform: uppercase;
					line-height: (2em/1.4);
					text-align: right;
					margin-top: .5em;
				}
				
				img {
					display: block;
					width: (8.4em/1.4);
					height: 2em;
				}

				p {
					clear: both;
					font-size: 1.2em;
					color: palette(grey);
					text-align: center;
				}
			}
		}
	}
	
	&--3 {
		@extend %cf;

		@include mq($tablet-wide) {
			padding-right: 55%;
			background: palette(blue)
				url('/Content/imgs/our-story/better-design-bkgd.jpg')
				center 
				no-repeat;
		}
	}

	&--4 {
		@extend %cf;
		background: palette(blue, dark-grey);

		.wrapper--text {
			margin-top: -1.3em; // to account for nested h1 margin
			
			@include mq($tablet-narrow) {
				width: 40%;
				float: left; 

				&:after {
					content: '';
					position: absolute;
					width: 6%;
					height: 1px;
					background-color: white;
					top: 50%;
					left: 46.75%; // i dunno why, but it works.			
				}
			}
		}

		.wrapper--figures {
			@include mq($tablet-narrow) {
				width: 47%;
				float: right;
				border-left-color: white;
				padding-left: 4em;

				&:before,
				&:after {
					background-color: white;
					border-color: white;
				}
			}

			@include mq($tablet-wide) {
				padding-left: 6em;
			}
		}
	}

	&--5 {
		background: palette(blue, light);

		@include mq($tablet-narrow) {
			background: palette(blue, light)
				url('/Content/imgs/our-story/better-results-bkgd.jpg')
				no-repeat;
			background-size: cover;
		}
		
		> .wrapper {
			@include mq($tablet-narrow) {
				width: 50%;
			}
		}

		> figure {
			@extend %cf;

			@include mq($tablet-narrow) {
				position: absolute;
				top: 8em;
				left: 60%;
			}

			@include mq($tablet-wide) {
				top: 4.6em;
				left: 55%;
			}

			> img {
				display: none;

				@include mq($tablet-wide) {
					display: block;
					float: left;
				}
			}

			> figcaption {
				@extend %cf;
				margin-top: 2em;
				float: left;

				@include mq($tablet-wide) {
					margin-top: 0;
				}
			}

			button {
				@extend %reset-button;
				padding: 1em;
				display: none;

				@include mq($tablet-wide) {
					display: block;
					float: left;
					clear: left;
					margin-bottom: 1em;

					&.is-shown {
						position: relative;

						&:before {
							content: '';
							display: block;
							position: absolute;
							background: white;
							width: 1.5em;
							height: 1px;
							bottom: 17px;
							right: -1em;
						}
					}
				}
			}

			p {
				margin: 0;

				@include mq($tablet-wide) {
					float: left;
					margin: -4px 0 0 24px;
					padding: .5em 1em;
					display: none;
					background: palette(blue, grey);
					position: relative;

					&.is-shown {
						display: block;

						&:before,
						&:after {
							content: '';
							display: block;
							position: absolute;
						}

						&:before {
							height: 34px;
							width: 100%;
							top: -15px;
							left: -15px;
							border-top: 1px solid white;
							border-left: 1px solid white;
							pointer-events: none;
						}

						&:after {
							background: white;
							height: .35em;
							width: .35em;
							border-radius: 50%;
							top: -17px;
							right: 1em;
						}
					}
				}

				&:before {
					content: '\2043';
					margin-right: .25em;

					@include mq($tablet-wide) {
						display: none;
					}
				}
			}
		}
	}
}