$font-size: 1.1;
$arrow-width: 1.8em / $font-size;
$arrow-height: 1.6em / $font-size;

.arrow-link {
	color: palette(red);
	text-transform: uppercase;
	padding-right: 1em;
	border-bottom: 5px solid palette(blue, light);
	display: inline-block;
	position: relative;
	font-size: #{$font-size}em;
	transition: border-color .3s;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: calc(100% + 6em);
		height: 6em;
		left: -2em;
		top: -2em;
	}

	&:after {
		content: '';
		display: block;
		width: $arrow-width;
		height: $arrow-height;
		position: absolute;
		bottom: -(1em/1.1);
		right: (-$arrow-width - .5em);
		background: url('/Content/imgs/arrow.svg') center no-repeat;
		background-size: contain;
		transition-property: width, height, bottom, right;
		transition-duration: .3s;
		transform: translate3d(0,0,0);
	}

	&:hover {
		border-color: palette(red);

		&:after {
			bottom: -(1em/1.1) * 1.5;
			right: (-$arrow-width - .5em) * 1.5;
			width: ($arrow-width * 1.5);
			height: ($arrow-height * 1.5);
		}
	}
}

button.arrow-link {
	@extend %reset-button;
	border-bottom: 5px solid palette(blue, light);
}