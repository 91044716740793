.red-wrapper {
	@include mq($tablet-narrow) {
		border-left: 1px solid palette(red);
		padding: 2em 0 2em 2em;
		position: relative;

		&:before,
		&:after {
			content: '';
			width: 4em;
			position: absolute;
			background-color: palette(red);
			left: 0;
			background-clip: content-box;
			height: 3px;
			padding: 1px 0;
			border-right: 3px solid palette(red);
		}

		&:before {
			top: -1px;
		}

		&:after {
			bottom: -1px;
		}
	}
}