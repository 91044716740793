.figure {
	@include mq($mobile-wide) {
		width: 50%;
		padding-right: 1em;
		float: left;
	}

	+ .figure {
		margin-top: 1em;
	
		@include mq($mobile-wide) {
			margin-top: 0;
			padding-right: 0;
			padding-left: 1em;
		}
	}

	img {
		display: block;
		margin: 0 auto 1em;
	}

	> figcaption {

	}

	h1 {
		background-color: palette(blue);
		text-align: center;
		font-size: 1.2em;
		padding: .5em 0;
		color: white;
	}

	p {
		margin: .5em 0 0;
	}
}